import classNames from 'classnames';
import React, { memo, useCallback, useState } from 'react';
import { IconCamera, IconMicrophone, IconTravelShow, IconWarning } from '@new/components/icon';
import { Flex } from '@new/components/ui';
import { WhiteContainer } from '../../components/WhiteContainer';
import cls from './TravelShow.module.css';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { Loader } from '../../components/Loader';

const DynamicVideoBlock = dynamic(() => import('./VideoBlock'), {
	ssr: false,
	loading: () => <Loader />,
});

export type TSocial = 'youtube' | 'vk';
export type TTypeContent = `videos` | `podcasts`;

interface ITravelShowProps {
	className?: string;
	detailsRoute?: string;
}

export const TravelShow = memo((props: ITravelShowProps) => {
	const { className, detailsRoute } = props;
	const [selectSocial, setSocial] = useState<TSocial>('youtube');
	const [typeContent, setTypeContent] = useState<TTypeContent>('videos');
	const [currentVideo, setCurrentVideo] = useState<number>(0);

	const handlerClickSocial = (item: TSocial) => () => {
		setCurrentVideo(0);
		setSocial(item);
	};
	const handlerClickType = (item: TTypeContent) => () => {
		setCurrentVideo(0);
		setTypeContent(item);
	};

	const handlerChangeVideo = useCallback(
		(nextIndex: number) => () => {
			setCurrentVideo(nextIndex);
		},
		[selectSocial, typeContent]
	);

	const { t } = useTranslation('ui');

	return (
		<WhiteContainer className={classNames(className, cls.TravelShow)}>
			<Flex className={cls.header} justifyContent='between' alignItems='center'>
				<Flex className={cls.title} alignItems='center' gap='8px'>
					<p>{t('_travelShow.title')}</p>
					<IconTravelShow />
				</Flex>
				<Flex alignItems='center' gap='20px'>
					<Flex
						className={classNames(cls.item, typeContent === 'videos' && cls.active)}
						gap='8px'
						alignItems='center'
						onClick={handlerClickType('videos')}
					>
						<Flex justifyContent='flex-center' alignItems='center'>
							<IconCamera />
						</Flex>
						<p>Travel Show</p>
					</Flex>
					<Flex
						className={classNames(cls.item, typeContent === 'podcasts' && cls.active)}
						gap='8px'
						alignItems='center'
						onClick={handlerClickType('podcasts')}
					>
						<Flex justifyContent='flex-center' alignItems='center'>
							<IconMicrophone />
						</Flex>
						<p>{t('_travelShow.podcasts')}</p>
					</Flex>
				</Flex>
			</Flex>
			<p className={cls.desc}>{t('_travelShow.desc')}</p>
			<Flex className={cls.videoBlock} gap='34px'>
				<DynamicVideoBlock
					selectSocial={selectSocial}
					typeContent={typeContent}
					onChangeVideo={handlerChangeVideo}
					videoIndex={currentVideo}
				/>
			</Flex>
			<Flex alignItems='center' justifyContent='between'>
				<Flex className={cls.left} alignItems='center' gap='8px'>
					{detailsRoute && (
						<>
							<IconWarning />
							<p>
								{t('_travelShow.offer')} <Link href={detailsRoute}>{t('_travelShow.link')}</Link>
							</p>
						</>
					)}
				</Flex>
				<Flex className={cls.right} alignItems='center' gap='8px'>
					<p>
						<b> {t('_travelShow.whereShow')}: </b>
						<span className={classNames(selectSocial === 'vk' && cls.active)} onClick={handlerClickSocial('vk')}>
							VK{' '}
						</span>
						/
						<span
							className={classNames(selectSocial === 'youtube' && cls.active)}
							onClick={handlerClickSocial('youtube')}
						>
							{'  '}
							YouTube
						</span>
					</p>
				</Flex>
			</Flex>
		</WhiteContainer>
	);
});
